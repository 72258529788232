$min-screen-width: 1600px;
$fixed-alert-bar: 40px;
$fixed-top-height: 50px;
$fixed-bottom-height: 0; // 44px;
$top-bar-bg: #151515;
$top-bar-bg-selection: #18A0FB;
$top-bar-fg: #FFF;

$desktop-ph: 1.5rem;
$desktop-pw: 5vw;

.mt-app-main {
  padding-top: $fixed-top-height;
  padding-bottom: $fixed-bottom-height;
  min-width: $min-screen-width;
  &.mt-show-alert-bar {
    padding-top: $fixed-alert-bar;
  }
}

.mt-app-top-bar {
  position: fixed;
  top: 0;
  .mt-show-alert-bar & {
    position: -webkit-sticky;
    position: sticky;
  }
  width: 100%;
  background-color: $top-bar-bg;
  color: $top-bar-fg;
  padding: 0 2rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: $fixed-top-height;
  z-index: 3;
}

.mt-alert-bar {
  padding: 0 2rem;
  // background-image: linear-gradient(89deg,#00a4bd 0,#00bda5 100%);
  background-image: linear-gradient(to bottom, #004f79 0%, #5E9DC0 100%);
  color: #FFF;
  position: fixed;
  top: 0;
  height: $fixed-alert-bar;
  display: flex;
  align-items: center;
  width: 100%;
  .p-button.p-button-text {
    color: #FFF;
    &:hover {
      color: #FFF;
      background: #5E9DC0;
    }
  }
}

.mt-app-bottom-bar {
  width: 100%;
  position: fixed;
  background-color: #FFF;
  height: $fixed-bottom-height;
  padding: 0 2rem;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1px solid var(--gray-300);
  z-index: 3;
  color: #7F8B96;
}

.mt-app-top-bar-divider {
  border-left: 1px solid #7F8B96;
  padding: 1px;
  height: $fixed-top-height * .6;
}

.mt-app-top-bar-avatar {
  width: $fixed-top-height * .6;
  height: $fixed-top-height * .6;
  border-radius: 50%;
  background-color: #9E9E9E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-app-desktop {
  padding: $desktop-ph $desktop-pw;
}

.mt-app-top-bar .p-menubar {
    padding: 0.24rem;
    border: none;

  .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    color: $top-bar-fg;
  }

  .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: $top-bar-bg-selection;
  }

  .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: $top-bar-fg;
    white-space: nowrap;
  }

  .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $top-bar-fg;
  }

  .p-menubar-root-list > .p-menuitem-active > p-menubarsub > .p-submenu-list {
    background-color: $top-bar-bg;
    margin-top: .35rem;
  }

  .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: $top-bar-fg;
  }

  .p-menuitem-link .p-menuitem-text {
    color: $top-bar-fg;
  }

  .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $top-bar-fg;
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: $top-bar-fg;
  }

  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: $top-bar-fg;
  }

  .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $top-bar-fg;
  }

  .p-menuitem-link {
    color: $top-bar-fg;
  }

  .p-menuitem-link:not(.p-disabled):hover {
    background: $top-bar-bg-selection;
  }

  .p-menuitem-link .p-submenu-icon {
    color: $top-bar-fg;
  }

  .p-submenu-list {
    background-color: $top-bar-bg;
    width: 14rem;
  }

  .p-menubar-button {
    color: $top-bar-fg;
  }

  .p-submenu-list .p-menu-separator {
    border-color: rgba(255, 255, 255, .36);
  }
}

.mt-main-menu-header .p-menuitem-link {
  opacity: .7;
}


.mt-app-top-bar .mt-main-menu-indented-item .p-menuitem-link .p-menuitem-text {
  margin-left: 1rem;
}

.mt-app-top-bar {
  @media screen and (max-width: 960px) {
    .p-menubar .p-menubar-root-list {
      width: 100vh;
      background: $top-bar-bg;
    }
  }
}

.mt-page-header {
  margin: (-$desktop-ph) (-$desktop-pw) 0;
  background-color: #FFF;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08)
}

.mt-page-header-content {
  padding: .75rem $desktop-pw;
  position: relative;
}

.mt-page-header-content:not(:last-child):after {
  content: "";
  display: block;
  border-bottom: 1px solid var(--surface-300);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.mt-page-title {
  font-size: 1.7rem;
}


.mt-page-menu-bar .p-menubar {
  padding: 0;
  border: none;
}

.mt-page-menu-bar .p-menuitem-link-active .p-menuitem-text {
  color: var(--primary-color) !important;
}
