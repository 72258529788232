.p-input-filled .p-inputtext {
  background-color: var(--surface-c);
  &:enabled:focus {
    background-color: var(--surface-200);
  }
  &:enabled:hover {
    background-color: var(--surface-200);
  }

}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #FDFDFD;
}

.p-datatable .p-datatable-header {
  background: #F0F5F9;
  border-width: 1px 0 0 0;
}

.p-datatable .p-datatable-thead > tr > th {
  background: #F0F5F9;
  color: #495057;
  border-width: 1px 0 1px 0;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #E9ECEF;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #F0F5F9;
}

.p-datatable .p-sortable-column.p-highlight.p-frozen-column:hover {
  background: #E9ECEF;
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.375rem 0.75rem;
  background: rgba(63, 81, 181, 0.12);
  color: #3F51B5;
  border-radius: 4px;
}

.p-inputgroup .p-chips {
  width: 100% !important;
}
